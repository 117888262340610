import config from '../config'
import axios from 'axios'

var baseUrl = config.baseUrl()

export default {
    getSites() {
        return axios.get(baseUrl + "/su/site", {
            headers: {
                Authorization: "Bearer " + localStorage.token,
            },
        });
    },
    getUser(email) {
        return axios.post(baseUrl + '/directory/query/', {
            'email': email
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.token,
            },
        })
    },
    deleteDevice(deviceId) {
        return axios.delete(baseUrl + '/device/' + deviceId, {
            headers: {
                Authorization: "Bearer " + localStorage.token,
            },
        })
    },
    addSite(site) {
        return axios.post(baseUrl + '/site', site, {
            headers: {
                Authorization: "Bearer " + localStorage.token,
            },
        })
    },
    getSiteAdmins(siteID) {
        return axios.get(baseUrl + '/site/' + siteID + '/admin', {
            headers: {
                Authorization: "Bearer " + localStorage.token,
            },
        })
    },
    updateSite(siteId, site) {
        return axios.put(baseUrl + '/site/' + siteId, site, {
            headers: {
                Authorization: "Bearer " + localStorage.token,
            },
        })
    },
    removeSite(siteId) {
        return axios.delete(baseUrl + '/site/' + siteId, {
            headers: {
                Authorization: "Bearer " + localStorage.token,
            },
        })
    },
    getLockFromSite(siteId) {
        return axios.get(baseUrl + '/su/site/' + siteId + '/device', {
            headers: {
                Authorization: "Bearer " + localStorage.token,
            },
        })
    }
}
